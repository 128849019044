<template>
  <div class="text-center px-md-4 pb-2">
    <h2>{{ stepText }}</h2>
    <b-progress
      :variant="buttonVariant"
      :value="selectedStep"
      :max="totalSteps"
      class="mb-5 mt-2 centered-box no-padding"
    />
    <b-aspect
      :aspect="aspect"
      class="centered-box three-round bg-white"
    >

      <slide-wrapper
        ref="slideContainer"
        @index-changed="indexChanged"
      >
        <slide-template
          title="Hi there. Let's get you set up."
          footer-text="Many of our Counselors (sub-clinical care) and Therapists (licensed) can meet in as little as 48 hours"
        >
          <b-img
            fluid
            src="https://s3.amazonaws.com/assets.mindfuli.com/images/Mindfuli-hero-graphic-app.png"
            class="w-50"
          />
        </slide-template>
        <slide-template
          title="You have three levels of service to choose from."
          footer-text="The Therapist Connect Tier offers 4 Therapy Sessions and as many additional Counseling Sessions as directed by the Clinician"
        >
          <product-slide />
        </slide-template>
        <slide-template
          title="You can choose how you pay."
          sub-title="Your Payment is encrypted and you can change how you pay anytime. Cancel easily online."
          footer-text="Mindfuli accepts virtually all Commercial Credit/Debit cards and insurance as covered by your plan. Insurance coverage is subject to verification of benefits with your Insurance Payer. Certain exclusions apply."
        >
          <pricing-slide />
        </slide-template>
        <slide-template
          title="Confirm that Mindfuli is a fit for who you are."
          footer-text="Sub-clinical counseling can be provided in all 50 States. Clinical Therapists are only authorized to give care in the State of California. Call 1-888-703-3004 for more information."
        >
          <right-fit-slide />
        </slide-template>
        <slide-template 
          title="Now let’s get to know you better."
        >
          <lets-go-slide />
        </slide-template>
      </slide-wrapper>

    </b-aspect>
    <div class="centered-box text-center mt-2">
      <b-button
        :variant="buttonVariant"
        size="lg"
        class="w-50"
        @click="nextSlide"
      >
        {{ buttonText }}
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BCardTitle,
  BRow,
  BCol,
  BContainer,
  BSpinner,
  BCarousel,
  BCarouselSlide,
  BJumbotron,
  BImg,
  BButton,
  BAspect,
  BProgress,
} from 'bootstrap-vue'
import SlideTemplate from './SlideTemplate.vue'
import SlideWrapper from './SlideWrapper.vue'
import ProductSlide from './ProductSlide.vue'
import PricingSlide from './PricingSlide.vue'
import RightFitSlide from './RightFitSlide.vue'
import LetsGoSlide from './LetsGoSlide.vue'

export default {
  name: 'SlideContainer',
  components: {
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BCardTitle,
    BAspect,
    BProgress,
    SlideTemplate,
    SlideWrapper,
    BImg,
    ProductSlide,
    PricingSlide,
    RightFitSlide,
    LetsGoSlide,
  },
  props: {},
  data() {
    return {
      aspect: '4:3',
      totalSteps: 0,
      selectedStep: 0,
    }
  },

  computed: {
    stepText() {
      if (this.selectedStep == this.totalSteps) {
        return 'CONGRATULATIONS'
      }
      return `STEP ${this.selectedStep} OF ${this.totalSteps - 1}`
    },
    buttonText() {
      if (this.selectedStep == this.totalSteps) {
        return "LET'S GO"
      }
      return 'NEXT'
    },
    buttonVariant() {
      if (this.selectedStep == this.totalSteps) {
        return 'info'
      }
      return 'primary'
    },
  },
  created() {

  },
  mounted() {

  },
  beforeDestory() {

  },
  methods: {
    indexChanged(data) {
      console.debug(`index changed:${data.selected} of ${data.total}`)
      this.selectedStep = data.selected
      this.totalSteps = data.total
    },
    nextSlide() {
      if (this.selectedStep == this.totalSteps) {
        this.$auth.loginWithRedirect({ screen_hint: 'signup' })
      } else { this.$refs.slideContainer.nextSlide() }
    },
    checkBackState() {
      console.debug(JSON.stringify(history.state))

      if (history.state.index > 0) {
        this.$refs.slideContainer.prevSlide()
      }
    },
  },
}
</script>

<style scoped>
.centered-box {
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  max-width: 900px;
  max-height: 750px;
}
.bigHeader {
  font-size: 8em;
}
.no-padding {
  padding-left: 0em !important;
  padding-right: 0em !important;
}
.three-round {
  border-radius: 0em 5em 5em 5em;
}

</style>
